import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import {
  fetchConfirmationLetter,
  updateConfirmationLetter,
} from "../../api/reportsApi";
import { LetterDto } from "../../types/Rows";
import { Button } from "@material-tailwind/react";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import { ConfirmationletterDocGenerator } from "./confirmationletterDocGenerator";
import { useUserContext } from "../user/authProvider";

export function ConfirmationLetterPage() {
  let { letterId } = useParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { parsedName } = useUserContext();

  const [confirmationLetter, setConfirmationLetter] =
    useState<LetterDto | null>(null);

  const { data } = useQuery(
    ["ConfirmationLetter", letterId],
    async () => fetchConfirmationLetter(letterId),
    {
      enabled: letterId != null,
      initialData: null,
    }
  );
  const setItems = useNavigationBadgesStore((state: any) => state.setItems);

  useEffect(() => {
    if (data) {
      setItems([
        { text: "Badania", url: "/" } as NavigationBadge,
        {
          text: data.studyName,
          url: "/study/" + data.studyId,
          // links: allStudies?.map((link) => ({
          //   name: link.name,
          //   value: "/study/" + link.id,
          // })),
        } as NavigationBadge,
        {
          text: data.reportName,
          url: "/reports/" + data.reportId,
          // links: studies?.resorts?.flatMap((resort) =>
          //   resort?.reports?.flatMap((report) => ({
          //     name: resort.name + " " + report.name,
          //     value: "/reports/" + report.id,
          //   }))
          // ),
        } as NavigationBadge,
        {
          text: "Confirmation letter",
          url: "",
          // links: studies?.resorts?.flatMap((resort) =>
          //   resort?.reports?.flatMap((report) => ({
          //     name: resort.name + " " + report.name,
          //     value: "/reports/" + report.id,
          //   }))
          // ),
        } as NavigationBadge,
      ]);
    }
  }, [data, setItems]);

  const { mutate: updateLetter } = useMutation(
    async (letter: LetterDto) => {
      return updateConfirmationLetter(letterId, letter);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ConfirmationLetter", letterId]);
      },
    }
  );

  useEffect(() => {
    if (data) {
      setConfirmationLetter(data);
    }
  }, [data]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setConfirmationLetter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDownload = async () => {
    const blob = await pdf(
      <ConfirmationletterDocGenerator confirmationLetter={confirmationLetter} author={parsedName} />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Confirmation-letter-${data.reportName}-${data.resortName}-${data.studyName}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("confirmationLetter is ", confirmationLetter);
    if (confirmationLetter) {
      updateLetter(confirmationLetter);
    }
  };

  if (!confirmationLetter) return <div>Loading...</div>;

  return (
    <>
      <div className="p-6 bg-gray-100 rounded-lg max-w-lg mx-auto m-5">
        <h1 className="text-2xl font-bold mb-4">
          {t("confirmationLetter")} {t("for")} {data.reportName} {"inResearch"}{" "}
          {data.studyName}
        </h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="form-group">
            <label className="block font-semibold mb-1">
              {t("mainResearcher")}:
            </label>
            <input
              disabled={true}
              type="text"
              name="mainResearcher"
              value={confirmationLetter.mainResearcher}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded bg-gray-200"
            />
          </div>
          <div className="form-group">
            <label className="block font-semibold mb-1">
              {t("protocolNumber")}:
            </label>
            <input
              disabled={true}
              type="text"
              name="protocolNumber"
              value={confirmationLetter.protocolNumber}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded bg-gray-200"
            />
          </div>
          <div className="form-group">
            <label className="block font-semibold mb-1">
              {t("resortNumber")}:
            </label>
            <input
              disabled={true}
              type="text"
              name="resortNumber"
              value={confirmationLetter.resortNumber}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded bg-gray-200"
            />
          </div>
          <div className="form-group">
            <label className="block font-semibold mb-1">
              {t("introductionText")}:
            </label>
            <input
              type="text"
              name="introductionText"
              value={confirmationLetter.introductionText}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="form-group">
            <label className="block font-semibold mb-1">
              {t("visitType")}:
            </label>
            <input
              type="text"
              name="visitType"
              value={confirmationLetter.visitType}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="form-group">
            <label className="block font-semibold mb-1">{t("visitDay")}:</label>
            <input
              type="text"
              name="visitDay"
              value={confirmationLetter.visitDay}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="form-group">
            <label className="block font-semibold mb-1">
              {t("visitHour")}:
            </label>
            <input
              type="text"
              name="visitHour"
              value={confirmationLetter.visitHour}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="form-group">
            <label className="block font-semibold mb-1">
              {t("researchTeam")}:
            </label>
            <textarea
              name="researchTeam"
              value={confirmationLetter.researchTeam}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="form-group">
            <label className="block font-semibold mb-1">
              {t("accessList")}:
            </label>
            <textarea
              name="accessList"
              value={confirmationLetter.accessList}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="form-group">
            <label className="block font-semibold mb-1">
              {t("visitReason")}:
            </label>
            <textarea
              name="visitReason"
              value={confirmationLetter.visitReason}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="form-group">
            <label className="block font-semibold mb-1">zespół CRO:</label>
            <textarea
              name="croTeam"
              value={confirmationLetter.croTeam}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="flex gap-2">
            <Button
              className=""
              type="submit"
              // className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
            >
              {t("save")}
            </Button>
            <Button variant="outlined" onClick={() => handleDownload()}>
              Exportuj do PDF
            </Button>{" "}
          </div>
        </form>
      </div>
    </>
  );
}
