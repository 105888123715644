import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDiscussion, useReport } from "../..";
import { addComment } from "../../api/reportsApi";
import CommentsModal from "../problemsSection/CommentsModal";

export const DiscussionComponent = ({ resourceId }: { resourceId: string }) => {
  const { report } = useReport();
  const { discussion } = useDiscussion();
  const queryClient = useQueryClient();

  const { mutate: addReviewComment } = useMutation(
    async ({ value }: { value: string }) =>
      await addComment(report.id, resourceId, value),
    {
      onSuccess: () => {
        queryClient.resetQueries(["Discussion"]);
      },
    }
  );

  return (
    <CommentsModal
      comments={discussion.get(resourceId) ?? []}
      addItem={(val) => addReviewComment({ value: val })}
      reportId={report.id}
    />
  );
};
