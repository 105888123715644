import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import {
  addResort,
  updateStudyDescription,
  deleteResort,
  addReport,
  editResort,
} from "../../api/studyApi";
import AddItemsModal from "../template/addItemsModal";
import AddItemModal from "../template/addItemModal";
import ItemsList from "../../components/lists/ItemsList";
import MagnifyingGlass from "../../assets/icons/MagnifyingGlass";
import { Input } from "@material-tailwind/react";
import AddIcon from "../../components/icons/AddIcon";
import { Report, ReportState } from "../../types/Rows";
import { Dropdown } from "flowbite-react";
import EditIcon from "../../components/icons/EditIcon";
import ReportDropdown from "../../components/ReportDropdown";
import DeleteIcon from "../../components/icons/DeleteIcon";

const Reports = ({ data, templates, studyId }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const onSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setSearchPhrase(target.value.toLocaleLowerCase());

  const { mutate: addResortMutation } = useMutation(
    async ({ name, address }: { name: string; address: string }) => {
      return addResort(data.id, name, address);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", data.id]);
      },
    }
  );

  const { mutate: editResortMutation } = useMutation(
    async ({
      resortId,
      name,
      address,
    }: {
      resortId: string;
      name: string;
      address: string;
    }) => {
      return editResort(data.id, resortId, name, address);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", data.id]);
      },
    }
  );

  const { mutate: updateDescription } = useMutation(
    async (description: string) => {
      return updateStudyDescription(data.id, description);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", data.id]);
      },
    }
  );

  const { mutate: resortDelete } = useMutation(
    async (resortId: string) => {
      return deleteResort(data.id, resortId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", data.id]);
      },
    }
  );

  const { mutate: addReportMutation } = useMutation(
    async ({
      resortId,
      templateId,
    }: {
      resortId: string;
      templateId: string;
    }) => {
      await addReport(data.id, resortId, templateId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", data.id]);
      },
    }
  );

  const AdditionalInfo = (data: Report) => {
    console.log("data is", data);
    if (!data) return null;
    let text = "";
    let colour = "";
    if (data.state == ReportState.INPROGRESS) {
      text = "W trakcie";
      colour = "gray-500";
    }
    if (data.state == ReportState.TO_VERIFY) {
      text = "Do weryfikacji";
      colour = "orange-500";
    }
    if (data.state == ReportState.TO_CORRECT) {
      text = "Do poprawy";
      colour = "orange-500";
    }
    if (data.state == ReportState.ACCEPTED) {
      text = "Zaakceptowano";
      colour = "green-600";
    }

    return (
      <div className="flex flex-row gap-2">
        <div className={`text-${colour} pr-5`}>{text}</div>
        <Button
          variant="outlined"
          onClick={() => navigate(`/confirmationletter/${data.id}`)}
        >
          Confirmation letter
        </Button>
      </div>
    );
  };

  return (
    <div>
      <div className="flex content-between justify-between">
        <div className="flex row">
          <h1 className="text-5xl font-extrabold dark:text-white ">
            {data.name}
          </h1>

          <AddItemsModal
            borderless
            text="Dodaj ośrodek:"
            headers={["Nazwa", "Adres"]}
            saveItems={(names: string[]) => {
              addResortMutation({
                name: names[0]?.toString() ?? "",
                address: names[1]?.toString() ?? "",
              });
            }}
          >
            <Button variant="text">
              <AddIcon />
            </Button>
          </AddItemsModal>
        </div>
        <AddItemModal
          text="Edytuj opis"
          borderless={true}
          saveItem={(value) => updateDescription(value)}
          val={data.description}
        >
          <EditIcon />
        </AddItemModal>
      </div>
      <div>
        <p className="p-3">{data.description} </p>
      </div>
      <div className="flex flex-col gap-5 divide-y-2">
        {data.resorts
          .sort((a, b) => (a.name < b.name ? -1 : 1))
          .map((resort) => (
            <div className="ml-4">
              <div className="flex flex-1 items-end justify-between">
                <div className="flex flex-1 items-end gap-3 ">
                  <h1 className="text-5xl font-extrabold dark:text-white">
                    <small className="font-semibold text-gray-500 dark:text-gray-400">
                      {resort.name}
                    </small>
                  </h1>{" "}
                  <ReportDropdown
                    resortId={resort.id}
                    templates={templates.filter((x) => x.studyId == studyId)}
                    addReportMutation={addReportMutation}
                  />
                </div>
                <div className="flex gap-2">
                  <AddItemsModal
                    borderless
                    text="Edytuj ośrodek:"
                    vals={[resort.name, resort.address]}
                    headers={["Nazwa", "Adres"]}
                    saveItems={(names: string[]) => {
                      editResortMutation({
                        resortId: resort.id,
                        name: names[0]?.toString() ?? "",
                        address: names[1]?.toString() ?? "",
                      });
                    }}
                  >
                    <div
                      className=" self-center p-1  cursor-pointer
      rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700
          flex
          "
                    >
                      <EditIcon />
                    </div>
                  </AddItemsModal>
                  <div
                    className=" self-center p-1  cursor-pointer
      rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700
          flex
          "
                    onClick={async () => {
                      const res = window.confirm("Czy chcesz usunąć?");
                      if (res) await resortDelete(resort.id);
                    }}
                  >
                    <DeleteIcon />
                  </div>{" "}
                </div>
              </div>

              <h1 className="font-extrabold dark:text-white">
                <small className="font-semibold italic text-gray-500 dark:text-gray-400">
                  {resort.address}
                </small>
              </h1>
              <div className="ml-3">
                <ItemsList
                  isLoading={false}
                  data={resort.reports}
                  urlPrefix="/reports/"
                  additionalInfoFactory={(data: Report) => AdditionalInfo(data)}
                >
                  {""}
                </ItemsList>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Reports;
