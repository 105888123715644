import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import {
  addIssuenDescription,
  getTemplateProblems
} from "../../api/questionsApi";
import ItemsList from "../../components/lists/ItemsList";
import { BaseDto, QuestionDTO } from "../../types/Rows";
import { AddIcon } from "../studies/AddIcon";
import AddItemModal from "../template/addItemModal";

export const IssuesPage = () => {
  const setItems = useNavigationBadgesStore((state: any) => state.setItems);
  const [mappedItems, setMappedItems] = useState<QuestionDTO[]>([]);
  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["Problems"],
    () => getTemplateProblems()
  );

  const queryClient = useQueryClient();

  const { mutate: addDescription } = useMutation(
    async (name: string) => {
      await addIssuenDescription({ value: name });
    },
    {
      onSuccess: () => {
        queryClient.resetQueries(["Problems"]);
      },
    }
  );

  useEffect(() => {
    if (data) {
      setItems([{ text: "Issues", url: "/Questions" } as NavigationBadge]);
      const mapped = data.categories.map(
        (x) =>
          ({
            id: x.id.toString(),
            description: x.value,
            name: x.value,
            answers: [],
          } as QuestionDTO)
      );

      setMappedItems(mapped);
    }
  }, [data, setItems]);

  return (
    <div className="mx-auto max-w-7xl sm:px-6 mt-3 lg:px-8">
      <div className="pb-8 sm:px-0">
        <ItemsList
          data={mappedItems.map((x) => {
            return {
              id: x.id,
              name: x.description,
              description: x.description,
              created: x.created,
              createdBy: x.createdBy,
            } as BaseDto;
          })}
          isLoading={isFetching}
          urlPrefix="questions/"
        >
          <header>
            <div className="max-w-7xl">
              <h1 className="text-3xl font-bold leading-tight text-gray-900 flex gap-1">
                {"Issues"}
                <AddItemModal
                  text={"Dodaj issue"}
                  saveItem={(name) => addDescription(name)}
                >
                  <AddIcon />
                </AddItemModal>
              </h1>
            </div>
          </header>{" "}
        </ItemsList>
      </div>
    </div>
  );
};
