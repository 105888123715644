import { useEffect } from "react";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import { content } from "./informationContent";

export const InformationPage = () => {
  const setItems = useNavigationBadgesStore((state: any) => state.setItems);

  useEffect(() => {
    setItems([{ text: "Informacje", url: "/Information" } as NavigationBadge]);
  }, []);

  return (
    <div className="mx-auto max-w-7xl sm:px-6 mt-3 lg:px-8">
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Spis treści</h2>
        <ul>
          {content.sections.map((section, index) => (
            <li key={index}>
              <a
                href={`#${section.title.replace(/\s+/g, "-").toLowerCase()}`}
                className="underline"
              >
                {section.title}
              </a>
            </li>
          ))}
        </ul>
        <hr className="my-4" />
      </div>
      {content.sections.map((section, index) => (
        <div
          key={index}
          id={section.title.replace(/\s+/g, "-").toLowerCase()}
          className="mb-8"
        >
          <h2 className="text-2xl font-bold mb-2">{section.title}</h2>
          <p className="whitespace-pre-line">{section.content}</p>
          <hr className="my-4" />
        </div>
      ))}
    </div>
  );
};
