export const content = {
  sections: [
    {
      title: "NOTATKA 1 PROPOZYCJE „GRUBYCH” ISSUES",
      content: `ODCHYLENIA OD PROTOKOŁU
    Dużo PDs związanych z kryteriami włączenia/wyłączenia i/lub z podaniem badanego produktu leczniczego i/lub pobraniem próbek biologicznych (ważnych z punktu widzenia celów badania).
    BEZPIECZEŃSTWO:
    • SAE nie zaraportowane.
    • SAE zaraportowane, ale z opóźnieniem.
    • AESI nie zaraportowane. 
    ŚWIADOMA ZGODA:
    • Wykonanie interwencji badania/odstawienie lub modyfikacja przyjmowanego przez pt leczenia/przed podpisaniem przez pacjenta ICF-u (dotyczy pierwszej i każdej następnej wersji zgody).
    • Procedury badania rozpoczęte (pobrane próbki na b. genetyczne) , a pacjent nie podpisał formularza/y: „ZGODY NA UDZIAŁ W BADANIU” i/lub „ZGODY NA PRZETWARZANIE DANYCH OSOBOWYCH” i/lub ZGODY NA POBIERANIE I ZABEZPIECZANIE MATERIAŁU DO BADAŃ GENETYCZNYCH. (dotyczy pierwszej i każdej następnej wersji zgody).
    • Zagubienie formularza/y ICF - brak ICF dla pacjenta przed 1-wszym SDV.
    PRODUKT BADANY:
    • Pacjent otrzymał badany produkt leczniczy: niezgodny z numerem randomizacji/bez rejestracji w „systemie”, tzn. tak z półki i/lub po dacie ważności i/lub produkt leczniczy, który był przechowywany niezgodnie z warunkami przechowywania i/lub z naruszeniem kryteriów włączenia/wyłączenia.
    • Badany produkt leczniczy przechowywany w nieodpowiednich warunkach, szczególnie dotyczących temperatury.
    • Badany produkt leczniczy z danego badania podany pt z innego bk lub pt, który nie uczestniczy w żadnym bk.
    • Złamanie kodu/ów randomizacyjnego/randomizacyjnych bez powodu.
    DOKUMENTACJA ŹRÓDŁOWA:
    • Brak dokumentacji źródłowej (braki w dokumentacji potwierdzające kryteria włączenia/wyłączenia i/lub dotyczące podania badanego produktu leczniczego).
    • Dokumentacja źródłowa niedostępna na wizycie. 
    GŁÓWNY BADACZ I ZESPÓŁ BADAWCZY:
    • GB nie jest dostępny do rozmowy/kontaktu (telefon, nie odpowiada na emaile) i/lub GB nie jest dostępny dla CRA przez 3 kolejne wizyty
    • Brak świadectw na nadzór GB nad badaniem: nie podpisuje SUSARów, issues (problemy) nie rozwiązane, tzn. nie ruszone od dwóch poprzednich wizyt.
    • Badacz/współbadacze nie zrobili szkolenia z CSP/procedur badania/ podawania produkt leczniczego itp. lub brak potwierdzenia odbycia tego typu szkoleń.
    PRZEGLĄD eCRF:
    • CRF-y nie wypełnione w ogóle dla pacjentów przed MV.
    LABORATORIUM/ VENDORZY ORAZ WYPOSAŻENIE OŚRODKA:
    INVESTIGATOR SITE FILE:
    Dotyczące Delegation Log:
    • Brak autoryzacji członka/ów zespołu przez GB lub autoryzacja GB dla członka/ów zespołu po rozpoczęciu czynności badania.
    • Brak współbadacza/y, farmaceuty na logu.
    • Zmiany w zespole badania nie odzwierciedlone na logu.
    INNE:
    Zmiany do protokołu wprowadzone w życie (wykonane procedury/zmiana leczenia) bez zgody RA.
    • Dokumenty, które powinien wypełnić pt np. dzienniczki wypełnione „nie przez pacjenta” (szczególnie z danymi wpływającymi na cele badania) np. wypełnione przez badacza/pielęgniarkę itp.`,
    },
    {
      title: "Pytania dla CRA",
      content: `Propozycja pytań dla CRA, które mogą być zadane w czasie przeprowadzania Zdalnej Wizyty Monitorującej (Remote Monitoring). 
Uwaga: Lista pytań jest dokumentem przygotowanym dla Monitora, opcjonalnym do używania. Jej celem jest pomoc w przeprowadzeniu zdalnej wizyty monitorującej. 
ZESPÓŁ
1. Czy od ostatniej wizyty zmienił się skład zespołu badawczego? Jeśli tak, czy Delegation of Responsibilities Log został właściwie uaktualniony? Czy zostały zebrane odpowiednie dokumenty dla nowych członków zespołu badania i są umieszczone w ISF (aktach Badacza)?
2. Czy istnieje dokumentacja potwierdzająca przeszkolenie nowego członka zespołu badania/odbycie przez nowego członka zespołu właściwych szkoleń? 
REKRUTACJA
3. Czy rekrutacja uczestników przebiega zgodnie z planem?
4. Czy został włączony nowy uczestnik w skiningu/czy został włączony/czy zrandomizowany?
5. Czy jakiś uczestnik zrezygnował z udziału w badaniu/został wyłączony/przedwcześnie zakończył udział? Jeśli tak, to z jakiego powodu i czy ta informacja została odnotowana w dokumentacji medycznej uczestnika? 
ODCHYLENIA OD PROTOKOŁU
6. Czy doszło do odchyleń od zasad Dobrej Praktyki Badań Klinicznych?
7. Czy doszło do odchyleń od protokołu?
8. Czy doszło do odchyleń, które spełniają kryteria Serious Breach (Poważnego Naruszenia)?
9. Czy wszystkie odchylenia są odpowiednio zapisane w dokumentacji medycznej uczestnika/innej dokumentacji badania, jak log odchyleń od protokołu, Formularz do raportowania Poważnych Naruszeń?
10. Czy doszło do odchyleń od innych procedur obowiązujących w badaniu? 
ŚWIADOMA ZGODA
11. Czy pojawiły się trudności z pozyskiwaniem świadomej zgody od uczestnika?
12. Czy nowy uczestnik/nowi uczestnicy podpisał/li i datował/li właściwy formularz świadomej zgody?
13. Czy uczestnicy podpisali uaktualnioną wersję świadomej zgody, jeśli pojawiła się w badaniu? 
ZDARZENIA NIEPOŻĄDANE/BEZPIECZEŃSTWO PACJENTÓW
14. Czy pojawiło się nowe AE/SAE/przypadek ciąży/inne zdarzenie wymagające raportowania?
15. Czy zakończyło się AE/SAE/ciąża/inne zdarzenie wymagające raportowania?
16. Czy SAE/przypadek ciąży zostały zaraportowane natychmiast (w ciągu 24 godzin)?
17. Czy SAE/ przypadek ciąży/ inne zdarzenie wymagające raportowania zostały zaraportowane zgodnie z wymaganiami badania?
18. Czy informacje dotyczące AE/SAE/ciąży/ innego zdarzenia wymagającego raportowania zostały odnotowane w dokumentacji medycznej uczestników/czy istnieje odpowiednia dokumentacja źródłowa dla tych zdarzeń?
19. Czy Główny Badacz zapoznał się i potwierdził zapoznanie się z raportami dotyczącymi bezpieczeństwa? 
DOKUMENTY/ADMINISTRACJA W OŚRODKU
20. Czy pojawiły się nowe wersje dokumentów? Jeśli tak, to gdzie zostały umieszczone?
21. Czy członkowie zespołu badania zapoznali się (odpowiednio) z jakimikolwiek nowymi i/lub nowymi wersjami dokumentów badania i proces zapoznania się został odpowiednio udokumentowany?
22. Czy ISF (akta Badacza) został uzupełniony od ostatniej wizyty monitorującej? Czy zawiera uaktualnione dokumenty itp.?
23. Czy ISF (akta Badacza) jest przechowywany w bezpiecznym miejscu, w tym z ograniczonym dostępem? 
BADANY PRODUKT (IMP)
24. Czy ośrodek ma wystarczającą ilość badanego produktu (IMP)?
25. Czy była nowa dostawa badanego produktu (IMP)?
26. Czy badany produkt (IMP) przechowywany jest zgodnie z obowiązującymi regulacjami i wymaganiami protokołu?
27. Czy były stwierdzone/odnotowane jakieś odchylenia od warunków przechowywania IMP, w tym od wymaganej temperatury przechowywania? Jeśli tak, to jakie to było odchylenie? Jakie działania podjęto? Do kogo odchylenie zostało zgłoszone? Czy zostało opisane/odpowiednio udokumentowane?
28. Czy doszło do przeterminowania badanego produktu? Jeśli tak, jakie działanie zostało podjęte? Czy zostało opisane/odpowiednio udokumentowane?
29. Czy przychód/rozchód badanego produktu w ośrodku jest odpowiednio dokumentowany na logach/formularzach itp.?
30. Czy wydanie/zwrot badanego produktu uczestnikowi jest udokumentowane w dokumentacji medycznej/dokumentacji badania?
31. Czy odnotowywana jest informacja dotycząca prawidłowości (compliance) przyjmowania badanego produktu przez uczestnika badania w dokumentacji medycznej uczestników/dokumentacji badania? Czy stosowany jest kalkulator do obliczeń prawidłowości przyjmowania (compliance)?
32. Czy doszło do złamania kodu randomizacyjnego/odślepienia uczestnika? 
LABORATORIUM
33. Czy ośrodek ma odpowiednią ilość zestawów laboratoryjnych?
34. Czy zestawy laboratoryjne mają aktualną datę ważności?
35. Czy próbki laboratoryjne są zbierane, przechowywane i transportowane zgodnie z procedurami badania? 
CRF
36. Czy zostały uzupełnione dane dotyczące wizyt/moduły w eCRF-ie? Jeśli nie, to jaki jest tego powód?
37. Czy zostały udzielone odpowiedzi na zadane nowe i/lub nie rozwiązane pytania (queries)?
38. Czy wszystkie osoby z zespołu uzupełniające dane w eCRF mają udokumentowane szkolenie i czy mają własny dostęp do eCRF (login/hasło)?
39. Czy były jakieś problemy z eCRF-em? 
SPRZĘT UŻYWANY W BADANIU
40. Czy jakiś sprzęt używany w badaniu został zmieniony?
41. Czy używany w badaniu sprzęt posiada aktualne paszporty techniczne/dokumenty przeglądów? 
INNE
42. Czy ośrodek ma odpowiednią ilość innych materiałów wymaganych w badaniu, jak np. dzienniczki uczestnika, kwestionariusze)?`,
    },
    {
      title: "REMOTE",
      content: `1. ODPOWIEDZIALNOŚĆ ZESPOŁU (wykonywanie (czynności zgodnie z Delegation of Responsibilities Log, dyspozycyjność w kontaktach telefonicznych/mailowych, zapewnienie ochrony danych uczestników, prowadzenie badania zgodnie z protokołem, ICH-GCP i innymi regulacjami) 
2. ODCHYLENIA OD PROTOKOŁU (dotyczące kryteriów włączenia/wykluczenia, procedur, okien czasowych wizyt, badań laboratoryjnych i innych możliwych do zidentyfikowania, (prawidłowe i terminowe uzupełnianie używanego w badaniu: Deviation List/Log/Form, jeśli prowadzona/-y w formie elektronicznej) 
3. ŚWIADOMA ZGODA (weryfikacja daty podpisania ICF i właściwej/aktualnej wersji ICF (numer i data wersji) podpisanej przez uczestnika z danymi w eCRF)) 
4. BEZPIECZEŃSTWO UCZESTNIKÓW (wystąpienie AE/SAE, innych zdarzeń wymagających raportowania, przypadków ciąży; prawidłowość raportowania (czas/format itp.), raportowanie informacji uzupełniających) 
5. ADMINISTRACJA W OŚRODKU (kompletność i uzupełnienie podstawowej dokumentacji badania w aktach Badacza (eISF) zgodnie ze spisem treści)) 
6. BADANY PRODUKT (IMP) (odnotowanie prawidłowego wydania/podania IMP w eCRF, umieszczenie w aktach Badacza (eISF) potwierdzenia dostaw IMP do ośrodka, weryfikacja logów temperatury i innych warunków przechowywania IMP itp.) 
7. LABORATORIUM (uzupełnienie akt Badacza (eISF) zgodnie ze spisem treści, weryfikacja temperatury (i innych warunków) przechowywania próbek i zarządzania próbkami) 
8. REKRUTACJA (prawidłowe i terminowe uzupełnienie Participant Screening & Enrollment Log, ICF Log - jeśli prowadzone w formie elektronicznej, dokumentacja przyczyn wyłączenia pacjentów z badania, poziom rekrutacji) 
9. ZARZĄDZANIE GROMADZENIEM DANYCH (prawidłowe i terminowe uzupełnienie eCRF, rozwiązanie założonych przez Monitora zapytań – queries) 
10. ZARZĄDZANIE RANDOMIZACJĄ (prawidłowe zarejestrowanie/zrandomizowanie uczestnika badania)`,
    },
  ],
};
