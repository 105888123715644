
import AddItemWithExistingPicker, { InputType } from "../../pages/template/AddItemWithExistingPicker";
import { PlusIcon } from "../../types/PlusIcon";
import { getTemplateProblems } from "../../api/questionsApi";
import { ProblemAnswer } from "../../types/Rows";

export const ProblemsHeader = ({
  saveProblemsAnswers,
  problemAnswers,
  addProblemQuestions,
  addProblemTypeToDictionary,
  reportId,
}: {
  saveProblemsAnswers: any;
  problemAnswers: Map<string, ProblemAnswer>;
  addProblemQuestions: ({ reportId, categoryId }: { reportId: string; categoryId: string }) => void;
  addProblemTypeToDictionary: ({ reportId, value }: { reportId: string; value: string }) => void;
  reportId: string;
}) => {
  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-11 gap-2
        w-full
        font-extrabold
        sm:rounded-lg
                   break-words 
         text-gray-700 uppercase  bg-gray-50 dark:bg-gray-700 dark:text-gray-400 p-2"
    >
      <div className="col-span-2">Kategoria</div>
      <div className="col-span-2">Opis</div>
      <div className="col-span-1">Status</div>
      <div className="col-span-2">Osoba identyfikująca</div>
      <div className="col-span-2">Data identyfikacji</div>
      <div className="col-span-2 flex justify-between">
        Data rozwiązania
        <AddItemWithExistingPicker
          saveExisting={(value) => {
            saveProblemsAnswers([...problemAnswers.values()]).then(() =>
              addProblemQuestions({
                reportId: reportId,
                categoryId: value,
              })
            );
          }}
          inputType={InputType.PICKFROMEXISTING}
          saveNew={(value) => {
            saveProblemsAnswers([...problemAnswers.values()]).then(() =>
              addProblemTypeToDictionary({
                reportId: reportId,
                value: value,
              })
            );
          }}
          items={async () => {
            let items = await getTemplateProblems();
            return items.categories.map((x) => ({
              id: x.id.toString(),
              name: x.value,
            }));
          }}
          text="Wybierz kategorię"
        >
          <PlusIcon />
        </AddItemWithExistingPicker>
      </div>
    </div>
  );
};