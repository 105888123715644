import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { useEffect, useRef, useState } from "react";
import { LetterDto } from "../../types/Rows";
import { headers } from "../../api/headers";
import ScientiaHeader from "../../components/ScientiaHeader";

Font.register({
  family: "Times New Roman",
  fonts: [
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
      fontWeight: 300,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
      fontWeight: 400,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
      fontWeight: 500,
    },
    {
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 600,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  headers: {
    display: "flex",
    flexDirection: "row",
  },
  page: {
    fontFamily: "Roboto",
    fontSize: 12,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: "white",
  },
  mainSection: {
    textAlign: "center",
    fontWeight: "semibold",
  },
  section: {
    marginBottom: 5,
    marginTop: 5,
    fontSize: 10,
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: "semibold",
  },
  importantParagraph: {
    fontSize: 14,
    fontWeight: "bold",
    marginTop: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  dateContainer: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
  },
});

const capitalizeWords = (str) =>
  str
    .split(" ")
    .map((word) => {
      return word
        .split("-")
        .map(
          (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
        )
        .join("-");
    })
    .join(" ");

const formatDate = (date) => {
  const d = new Date(date);
  let day = d.getDate().toString().padStart(2, "0");
  let month = (d.getMonth() + 1).toString().padStart(2, "0");
  const year = d.getFullYear();

  return `${day}/${month}/${year}`;
};
var logoUrl = require("../../assets/logo-scientia.png");

export const ConfirmationletterDocGenerator = ({
  confirmationLetter,
  author,
}: {
  confirmationLetter: LetterDto;
  author: string;
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <ScientiaHeader />
        <View style={styles.dateContainer}>
          <Text>Data: {formatDate(new Date())}</Text>
        </View>
        <View style={styles.headers}>
          <Text style={{ fontWeight: "semibold" }}>
            Imię i nazwisko głównego badacza:{" "}
          </Text>
          <Text> {confirmationLetter.mainResearcher}</Text>
        </View>
        <View style={styles.headers}>
          <Text style={{ fontWeight: "semibold" }}>Numer Protokołu: </Text>
          <Text>{confirmationLetter.resortNumber}</Text>
        </View>
        <View style={styles.headers}>
          <Text style={{ fontWeight: "semibold" }}>Numer Ośrodka: </Text>{" "}
          <Text>{confirmationLetter.resortNumber}</Text>
        </View>
        <View style={styles.mainSection}>
          <Text>List potwierdzający wizytę w ośrodku</Text>
        </View>
        <View style={styles.section}>
          <Text>Szanowny Panie Doktorze,</Text>

          <Text>
            Niniejszy list ma na celu potwierdzić wizytę{" "}
            {confirmationLetter.visitType} w Pana Ośrodku w dn.{" "}
            {confirmationLetter.visitDay}. Planujemy przybyć ok. godz.{" "}
            {confirmationLetter.visitHour}.
          </Text>
        </View>
        <View style={styles.section}>
          <Text>
            Podczas wizyty powinni być obecni Członkowie Zespołu, a w
            szczególności:
          </Text>
          <Text>{confirmationLetter.researchTeam}</Text>
        </View>
        <View style={styles.section}>
          <Text>Podczas wizyty będę potrzebować dostępu do:</Text>
          <Text>{confirmationLetter.accessList}</Text>
        </View>
        <View style={styles.section}>
          <Text>Podczas wizyty monitorującej spotkam się z Państwem, aby </Text>
          <Text>{confirmationLetter.visitReason} </Text>
        </View>
        <View style={styles.section}>
          <Text>
            W przypadku jakichkolwiek pytań proszę o kontakt z naszym zespołem
            CRO:
          </Text>
          <Text>{confirmationLetter.croTeam}</Text>
        </View>
        <View style={styles.section}>
          <Text>Z poważaniem,</Text>
          <Text>{author}</Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};
