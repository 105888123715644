import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";

const logoUrl = require("../assets/logo-scientia.png");
const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  line: {
    borderBottomWidth: 1,
    borderBottomColor: "rgb(235, 124, 49)",
    marginBottom: 20,
  },
});

const ScientiaHeader = () => {
  return (
    <>
      <View style={styles.header}>
        <Text
          style={{
            textAlign: "center",
            fontSize: 10,
            color: "grey",
          }}
        >
          PROVIDING THE BEST IN CLINICAL RESEARCH
        </Text>
        <Image src={logoUrl} style={{ width: "6cm", height: "2cm" }} />
      </View>
      <View style={styles.line} />
    </>
  );
};

export default ScientiaHeader;
